const About = () => {
    return (
        <div className='aboutSection'>
            <p>iO Academipsum is really useful software development themed dummy copy for all of your placeholder
                text needs! Created with love by students of iO Academy, Bath, UK.</p>
        </div>
    )
}

export default About
